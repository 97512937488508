import React, { useState, useEffect } from 'react';
import { Text, View, TextInput } from 'react-native';
import { Button } from 'react-native-elements';
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import { useProjectContext, Project } from '../utils/ProjectContext'; // Adjust the path as necessary
import ProjectEditor from '../components/ProjectEditor'; // Adjust the path as necessary
import { styles } from '../styles';
import { Card } from '@rneui/themed';
import VideoBackground from '../components/VideoBackground';

type StackParamList = {
    'Edit Project': { project: Project };
};

type EditProjectScreenRouteProp = RouteProp<StackParamList, 'Edit Project'>;

const EditProjectScreen: React.FC = () => {
    const navigation = useNavigation();
    const route = useRoute<EditProjectScreenRouteProp>();
    const { project } = route.params;
    const { dispatch } = useProjectContext();
    const [name, setName] = useState(project.name);
    const [goal, setGoal] = useState(project.goal);

    const handleSave = (prj: Project) => {
        if (project.id != prj.id) {
            console.log("save project ", prj);
        }

        if (project.id === 'new') {
            dispatch({ type: 'ADD_PROJECT', payload: { id: Date.now().toString(), name: prj.name, goal: prj.goal } });
        } else {
            dispatch({ type: 'UPDATE_PROJECT', payload: { ...project, name: prj.name, goal: prj.goal } });
        }
        navigation.goBack();
    };

    return (
        <VideoBackground videoSource={require('../assets/aimsherpa.mp4')}>
            <View style={styles.cardContainerFull}>
                <ProjectEditor project={project} onSave={handleSave} />
            </View>
        </VideoBackground>
    );
};

export default EditProjectScreen;
