import { useEffect, useRef, useState } from 'react';
import { Auth, Unsubscribe, onAuthStateChanged } from 'firebase/auth';
import 'firebaseui/dist/firebaseui.css';
import * as firebaseui from "firebaseui";

interface Props {
    // The Firebase UI Web UI Config object.
    // See: https://github.com/firebase/firebaseui-web#configuration
    uiConfig: firebaseui.auth.Config;
    // Callback that will be passed the FirebaseUi instance before it is
    // started. This allows access to certain configuration options such as
    // disableAutoSignIn().
    uiCallback?(ui: firebaseui.auth.AuthUI): void;
    // The Firebase App auth instance to use.
    firebaseAuth: Auth; // As firebaseui-web
    className?: string;
}

const StyledFirebaseAuth = ({ uiConfig, firebaseAuth, className, uiCallback }: Props) => {
    const [userSignedIn, setUserSignedIn] = useState(false);
    const elementRef = useRef(null);

    useEffect(() => {
        if (!elementRef.current) return;

        let firebaseUiWidget: firebaseui.auth.AuthUI;
        let unregisterAuthObserver: Unsubscribe;

        // firebase ui start in event loop to solve react 18 strict requirement.
        const timeout = setTimeout(() => {
            // Get or Create a firebaseUI instance.
            firebaseUiWidget =
                firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebaseAuth);
            if (uiConfig.signInFlow === "popup") firebaseUiWidget.reset();

            // We track the auth state to reset firebaseUi if the user signs out.
            unregisterAuthObserver = onAuthStateChanged(firebaseAuth, (user) => {
                if (!user && userSignedIn) firebaseUiWidget.reset();
                setUserSignedIn(!!user);
            });

            // Trigger the callback if any was set.
            if (uiCallback) uiCallback(firebaseUiWidget);

            // Render the firebaseUi Widget.
            if (elementRef.current)
                firebaseUiWidget.start(elementRef.current as HTMLDivElement, uiConfig);
        });

        return () => {
            clearTimeout(timeout);
            if (unregisterAuthObserver) unregisterAuthObserver();
            if (firebaseUiWidget) firebaseUiWidget.reset();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uiConfig, firebaseAuth, elementRef, uiCallback]);

    return <div className={className} ref={elementRef} />;
};

export default StyledFirebaseAuth;
