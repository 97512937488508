import { EmailAuthProvider, GoogleAuthProvider, SignInMethod, getAuth } from 'firebase/auth';
import React from 'react';
import { Text } from 'react-native';
import StyledFirebaseAuth from '../utils/StyledFirebaseAuth';
import { styles } from '../styles';
import { Card } from '@rneui/themed';
import WebLinearGradient from 'react-native-web-linear-gradient';
import VideoBackground from '../components/VideoBackground';
import { auth as firebaseuiAuth } from 'firebaseui';

export default function SignInScreen() {
    const uiConfig = {
        signInFlow: 'popup',
        signInSuccessUrl: '/', // Your post-login URL
        signInOptions: [
            {
                provider: GoogleAuthProvider.PROVIDER_ID,
                fullLabel: 'Sign in with Google',
                requireDisplayName: true,
                loginHintKey: 'login_hint',
            },
        ],
        tosUrl: 'https://appapt.com/dev',
        privacyPolicyUrl: 'https://appapt.com/privacy',
        credentialHelper: firebaseuiAuth.CredentialHelper.GOOGLE_YOLO
    };

    return (
        <VideoBackground videoSource={require('../assets/aimsherpa.mp4')} >
            <Card containerStyle={styles.cardContainer}>
                <Text style={styles.description}>Sign in to Aim Sherpa:</Text>
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={getAuth()} />
            </Card>
        </VideoBackground>
    );
}

