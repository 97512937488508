import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import ProjectsScreen from '../screens/Projects';
import { CustomHeader } from './CustomHeader';
import EditProjectScreen from '../screens/EditProject';
import { Project } from '../utils/ProjectContext';

export type ProjectStackParamList = {
    Projects: undefined;
    'Edit Project': { project: Project };
    Tasks: undefined;
};

const Stack = createNativeStackNavigator<ProjectStackParamList>();

export default function ProjectStack() {
    return (
        <Stack.Navigator initialRouteName='Projects'>
            <Stack.Screen
                name="Edit Project"
                component={EditProjectScreen}
                options={{ header: (props) => <CustomHeader {...props} /> }}
            />
            <Stack.Screen
                name="Projects"
                component={ProjectsScreen}
                options={{ header: (props) => <CustomHeader {...props} /> }}
            />
        </Stack.Navigator>
    );
}
