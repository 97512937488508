import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, User, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';

const auth = getAuth();

export function useAuthentication() {
  const [user, setUser] = useState<User>();
  //  const [user, setUser] = React.useState<User>();
  const [alphaTester, setAlphaTester] = useState<boolean>(false);

  useEffect(() => {
    const unsubscribeFromAuthStatusChanged = 
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          console.log("User: ", user);
          setUser(user);
          const tokenResult = await user.getIdTokenResult();
          //console.log("User Claims: ", tokenResult.claims);
          console.log ("Alpha Tester Role: ", tokenResult.claims.alpha_tester);
          setAlphaTester(tokenResult.claims.alpha_tester as boolean);
        } else {
          // User is signed out
          console.log("User: set to undefined");
          setUser(undefined);
          setAlphaTester(false);
        }
      });

    return unsubscribeFromAuthStatusChanged;
  }, []);

  return {
    user, alphaTester
  };
}