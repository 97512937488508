import React, { createContext, useReducer, useContext, ReactNode, Dispatch, useEffect } from 'react';
import { firestore } from '../firebaseConfig';
import { collection, doc, setDoc, deleteDoc, onSnapshot } from 'firebase/firestore';
import { useAuthentication } from '../utils/hooks/useAuthentication'; // Ensure you have user authentication set up

export interface Project {
    id: string;
    name: string;
    goal: string;
    aiResponse?: string;
}

type ProjectState = {
    projects: Project[];
};

type Action =
    | { type: 'ADD_PROJECT'; payload: Project }
    | { type: 'UPDATE_PROJECT'; payload: Project }
    | { type: 'DELETE_PROJECT'; payload: string }
    | { type: 'SET_AI_RESPONSE'; payload: { id: string; aiResponse: string } }
    | { type: 'SET_PROJECTS'; payload: Project[] };

const initialState: ProjectState = {
    projects: [],
};

const ProjectContext = createContext<{
    state: ProjectState;
    dispatch: Dispatch<Action>;
}>({
    state: initialState,
    dispatch: () => null,
});

const projectReducer = (state: ProjectState, action: Action): ProjectState => {
    switch (action.type) {
        case 'ADD_PROJECT':
            return {
                ...state,
                projects: [...state.projects, action.payload],
            };
        case 'UPDATE_PROJECT':
            return {
                ...state,
                projects: state.projects.map((project) =>
                    project.id === action.payload.id ? action.payload : project
                ),
            };
        case 'DELETE_PROJECT':
            return {
                ...state,
                projects: state.projects.filter((project) => project.id !== action.payload),
            };
        case 'SET_AI_RESPONSE':
            return {
                ...state,
                projects: state.projects.map((project) =>
                    project.id === action.payload.id ? { ...project, aiResponse: action.payload.aiResponse } : project
                ),
            };
        case 'SET_PROJECTS':
            return {
                ...state,
                projects: action.payload,
            };
        default:
            return state;
    }
};

export const ProjectProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(projectReducer, initialState);
    const { user } = useAuthentication(); // Assume you have a custom hook to get the authenticated user

    // Load projects from Firestore on mount
    useEffect(() => {
        if (!user) return;

        const projectRef = collection(firestore, 'users', user.uid, 'projects');
        const unsubscribe = onSnapshot(projectRef, (snapshot) => {
            const projects: Project[] = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            })) as Project[];
            dispatch({ type: 'SET_PROJECTS', payload: projects });
        });

        return () => {
            unsubscribe();
        };
    }, [user]);

    // Save projects to Firestore
    const saveProject = async (project: Project) => {
        if (!user) return;

        const projectRef = doc(firestore, 'users', user.uid, 'projects', project.id);
        await setDoc(projectRef, project);
    };

    const deleteProject = async (id: string) => {
        if (!user) return;

        const projectRef = doc(firestore, 'users', user.uid, 'projects', id);
        await deleteDoc(projectRef);
    };

    useEffect(() => {
        state.projects.forEach(saveProject);
    }, [state.projects]);

    return (
        <ProjectContext.Provider value={{ state, dispatch }}>
            {children}
        </ProjectContext.Provider>
    );
};

export const useProjectContext = () => useContext(ProjectContext);
