import React from 'react';
import { View, Text } from 'react-native';
import { useAuthentication } from '../utils/hooks/useAuthentication';
import { Button } from 'react-native-elements';
import 'firebase/auth';
import { getAuth, signOut } from 'firebase/auth';
import { styles } from '../styles';
import { Card } from '@rneui/themed';
import VideoBackground from '../components/VideoBackground';

export default function HomeScreen() {
    const { user } = useAuthentication();
    const userPhotoURL = user?.photoURL;
    const signOutUser = async (): Promise<void> => {
        try {
            const auth = getAuth();
            await signOut(auth);
            console.log('User signed out successfully');
            // Additional logic after successful sign out (e.g., updating state or redirecting)
        } catch (error) {
            console.error('Error signing out: ', error);
            // Handle any errors during sign out
        }
    };
    return (
            <Card containerStyle={styles.cardContainer}>
                <View style={{ position: "relative", alignItems: "center", borderWidth: 0, borderColor: 'red', flexDirection: 'row' }}>
                    <Card.Image source={{ uri: userPhotoURL as string }} style={{ width: 96, height: 96, alignSelf: 'center' }} resizeMode='contain' />
                    <Card.Title>{"\t"}Welcome, {user?.displayName}!</Card.Title>
                </View>
                <Card.Divider />
                <Text style={styles.description}>Thank you for signing up. We'll notify you at {user?.email} when we launch.</Text>
                <Card.Divider />
                <Button title="Sign Out" buttonStyle={styles.button} onPress={signOutUser} />
                <Card.Divider />
            </Card>
    );
}

