import React from 'react';
import { Button } from 'react-native-elements';
import { View, Text, FlatList } from 'react-native';
import { Project, useProjectContext } from '../utils/ProjectContext'; // Adjust the path as necessary
import ProjectEditor from '../components/ProjectEditor'; // Adjust the path as necessary
import { useNavigation, NavigationProp } from '@react-navigation/native';
import { styles } from '../styles';

const ProjectList: React.FC = () => {
    const { state, dispatch } = useProjectContext();
    const navigation = useNavigation<NavigationProp<any>>();
    const blankProject: Project = { id: 'new', name: '', goal: '' };

    const handleFirstProject = (project: Project) => {
        dispatch({ type: 'ADD_PROJECT', payload: project });
        console.log('ProjectList', `Project ${project.name} has been added.`);
    };

    const handleEditProject = (project: Project) => {
        navigation.navigate('Edit Project', { project });
    };

    const handleCreateProject = () => {
        navigation.navigate('Edit Project', { project: blankProject });
    };

    return (
        <View style={styles.cardContainerFull}>
                {state.projects.length === 0 ?
                    (<ProjectEditor project={blankProject} onSave={handleFirstProject} />) :
                    (<View style={styles.content}>
                        <View style={styles.tableHeader}>
                            <Text style={[styles.tableCell, styles.tableHeaderCell]}>Project Name</Text>
                            <Text style={[styles.tableCell, styles.tableHeaderCell]}>Goal</Text>
                            <Text style={[styles.tableCell, styles.tableHeaderCell]}>Edit</Text>
                            <Text style={[styles.tableCell, styles.tableHeaderCell]}>Delete</Text>
                        </View>
                        <FlatList
                            data={state.projects}
                            keyExtractor={(item) => item.id}
                            renderItem={({ item }) => (
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableCell}>{item.name}</Text>
                                    <Text style={styles.tableCell}>{item.goal}</Text>
                                    <View style={styles.tableCell}>
                                        <Button title="Edit" onPress={() => handleEditProject(item)} />
                                    </View>
                                    <View style={styles.tableCell}>
                                        <Button title="Delete" onPress={() => dispatch({ type: 'DELETE_PROJECT', payload: item.id })} />
                                    </View>
                                </View>
                            )}
                        />
                    </View>
                    )}
            <Button title="Add Project" onPress={handleCreateProject} />
        </View>
    );
};

export default ProjectList;

