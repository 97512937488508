// Footer.tsx
import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Linking, Image, Platform } from 'react-native';
import { styles } from '../styles';

const TouchFooter = () => {
    // Function to handle link press
    const handleLinkPress = (url: string) => {
        console.log(`Link pressed: ${url}`);
        if (Platform.OS === 'web') {
            // For web, change the current window location
            window.location.href = url;
        } else {
            // For other platforms, use Linking as usual
            Linking.openURL(url);
        }
    };

    return (
        <View style={styles.footer}>
            <TouchableOpacity onPress={() => handleLinkPress('https://appapt.com')} style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Text>(c) 2024 App Apt LLC  </Text>
                <Image source={require('../assets/_logo.svg')} style={{ width: 60, height: 20 }} resizeMode="contain" />
            </TouchableOpacity>
            <Text>  |  </Text>
            <TouchableOpacity onPress={() => handleLinkPress('https://appapt.com/dev')}>
                <Text style={styles.footerText}>Contact</Text>
            </TouchableOpacity>
            <Text>  |  </Text>
            <TouchableOpacity onPress={() => handleLinkPress('https://appapt.com/privacy')}>
                <Text style={styles.footerText}>Privacy</Text>
            </TouchableOpacity>
        </View>
    );
};

export default TouchFooter;
