import { createStackNavigator } from '@react-navigation/stack';
import WelcomeScreen from '../screens/Welcome';
import SignInScreen from '../screens/SignIn';
import SignOutScreen from '../screens/Welcome';
import SignUpScreen from '../screens/SignUp';

const Stack = createStackNavigator();

export default function AuthStack() {
    return (
        <Stack.Navigator>
            <Stack.Screen name="Welcome" options={{ title: 'Aim Sherpa' }} component={WelcomeScreen} />
            <Stack.Screen name="Sign Up" options={{ title: 'Aim Sherpa \\ Sign Up' }} component={SignUpScreen} />
            <Stack.Screen name="Sign In" options={{ title: 'Aim Sherpa \\ Sign In' }} component={SignInScreen} />
            <Stack.Screen name="Sign Out" options={{ title: 'Aim Sherpa \\ Sign Out' }} component={SignOutScreen} />
        </Stack.Navigator>
    );
}

