import React from 'react';
import { View, Platform } from 'react-native';
import { Button } from 'react-native-elements';
import * as Animatable from 'react-native-animatable';
import { StackScreenProps } from '@react-navigation/stack';
import { Divider, Card } from '@rneui/themed';
import { styles } from '../styles';
import VideoBackground from '../components/VideoBackground';

console.log("platform ", Platform.OS);

const WelcomeScreen: React.FC<StackScreenProps<any>> = ({ navigation }) => {
    return (
        <VideoBackground videoSource={require('../assets/aimsherpa.mp4')} >
            <Animatable.View animation="fadeInUp" duration={1200} style={styles.content}>
                <Animatable.Text animation="zoomIn" iterationCount={1} delay={600} direction="alternate" style={styles.title}>
                    Welcome to Aim Sherpa, the trusted AI guide for achieving your goals.
                </Animatable.Text>
                <View style={styles.content}>
                    <Card containerStyle={styles.cardContainer}>
                        <Animatable.Text animation="fadeIn" delay={600} style={styles.description}>
                            Aim Sherpa is the new way to get organized ang get things done.{"\n\n"}
                            Harness the power of AI to streamline your project planning and execution.{"\n\n"}
                            Set goals, automatically generate tasks to achieve them,and track progress.
                        </Animatable.Text>
                        <Animatable.View animation="jello" duration={5000} iterationCount="infinite" direction="normal" >
                            <Button title="Get Started for Free -- Sign up" buttonStyle={styles.button} onPress={() => navigation.navigate('Sign Up')} />
                        </Animatable.View>
                        <Divider style={styles.divider} />
                        <Animatable.Text style={styles.description}>Already have an account?</Animatable.Text>
                        <Button title="Sign in" buttonStyle={styles.button} onPress={() => navigation.navigate('Sign In')} />
                    </Card>
                </View>
            </Animatable.View>
        </VideoBackground>
    );
};

export default WelcomeScreen;

