import React, { useState, useEffect } from 'react';

interface Subtask {
    Task: string;
    Subtasks: string[];
}

interface Data {
    Goal: string;
    Tasks: string[];
    Subtasks: Subtask[];
}

export interface CheckboxJsonViewProps {
    jsonData: string;  // Accepting JSON as a string
}

interface CheckedTasks {
    [key: string]: { [subtask: string]: boolean };
}

const CheckboxJsonView: React.FC<CheckboxJsonViewProps> = ({ jsonData }) => {
    const [data, setData] = useState<Data | null>(null);
    const [checkedTasks, setCheckedTasks] = useState<CheckedTasks>({});
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        try {
            console.log(jsonData)
            const parsedData = JSON.parse(jsonData) as Data;
            validateData(parsedData); // Assuming validateData is a function that throws if data is invalid
            setData(parsedData);
            initializeCheckedTasks(parsedData);
        } catch (e) {
            setError('Failed to parse or validate JSON data');
            console.error(e);
        }
    }, [jsonData]);

    const validateData = (data: any) => {
        if (!data.Goal || !Array.isArray(data.Tasks) || !Array.isArray(data.Subtasks)) {
            throw new Error('Invalid data structure');
        }
        // Additional validation can be added here
    };

    const initializeCheckedTasks = (data: Data) => {
        const initialCheckedTasks: CheckedTasks = {};
        data.Tasks.forEach(task => {
            initialCheckedTasks[task] = {};
            const foundSubtaskGroup = data.Subtasks.find(sub => sub.Task === task);
            if (foundSubtaskGroup) {
                foundSubtaskGroup.Subtasks.forEach(subtask => {
                    initialCheckedTasks[task][subtask] = false;
                });
            }
        });
        setCheckedTasks(initialCheckedTasks);
    };

    const handleTaskCheck = (task: string, isSubtask: boolean = false, parentTask: string | null = null) => {
        const updatedChecked = { ...checkedTasks };
        if (isSubtask && parentTask) {
            updatedChecked[parentTask][task] = !updatedChecked[parentTask][task];
        } else {
            Object.keys(updatedChecked[task]).forEach(subtask => {
                updatedChecked[task][subtask] = !updatedChecked[task][subtask];
            });
        }
        setCheckedTasks(updatedChecked);
    };

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!data) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h1>{data.Goal}</h1>
            <ul>
                {data.Tasks.map(task => (
                    <li key={task}>
                        <label>
                            <input
                                type="checkbox"
                                checked={checkedTasks[task] && Object.values(checkedTasks[task]).every(Boolean)}
                                onChange={() => handleTaskCheck(task)}
                            />
                            {task}
                        </label>
                        <ul>
                            {data.Subtasks.find(sub => sub.Task === task)?.Subtasks.map(subtask => (
                                <li key={subtask}>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={checkedTasks[task] && checkedTasks[task][subtask]}
                                            onChange={() => handleTaskCheck(subtask, true, task)}
                                        />
                                        {subtask}
                                    </label>
                                </li>
                            ))}
                        </ul>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CheckboxJsonView;
