import firebaseConfig from './firebaseConfig';
import { initializeApp, getApps, getApp } from "firebase/app";
import { getAnalytics, isSupported } from "firebase/analytics";
import RootNavigation from './navigation/RootNavigation';
import { Platform } from 'react-native';
import { ProjectProvider } from './utils/ProjectContext';

// Initialize Firebase
const firebaseApp = !getApps().length ? initializeApp(firebaseConfig) : getApp();

// Initialize Firebase Analytics if supported
(async () => {
  if (await isSupported()) {
    console.log("platform ", Platform.OS);
    getAnalytics(firebaseApp);
  }
})();

export default function App() {
  return (
    <ProjectProvider>
      <RootNavigation />
    </ProjectProvider>
  );
}