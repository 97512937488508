import React from 'react';
import { Text, View } from 'react-native';
import { Button } from 'react-native-elements';
import { styles } from '../styles';
import { useAuthentication } from '../utils/hooks/useAuthentication';
import { getAuth, signOut } from 'firebase/auth';
import { Image } from 'react-native-elements';

const MiniUserBadge = () => {
    //const isDarkMode = useColorScheme() === 'dark';
    const { user } = useAuthentication();
    const userPhotoURL = user?.photoURL;
    const signOutUser = async (): Promise<void> => {
        try {
            await signOut(getAuth());
            console.log('User signed out successfully');
            // Additional logic after successful sign out (e.g., updating state or redirecting)
        } catch (error) {
            console.error('Error signing out: ', error);
            // Handle any errors during sign out
        }
    };
    return (
        <View style={styles.miniCardContainer}>
            <Image source={{ uri: userPhotoURL as string }} style={styles.miniUserImage} />
            {/*<Text>{user?.displayName}</Text> */}
            <Button title="Sign Out" buttonStyle={styles.miniButton} titleStyle={styles.miniButtonText} onPress={signOutUser} />
        </View>
    );
};

export default MiniUserBadge;
