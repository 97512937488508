import React, { useState, useEffect } from 'react';
import { Text, View } from 'react-native';
import { styles } from '../styles';
import * as Animatable from 'react-native-animatable';
import { GoogleGenerativeAI } from "@google/generative-ai";
import CheckboxJsonView from './CheckboxJsonView';
import { Project, useProjectContext } from '../utils/ProjectContext';

interface TaskListProps {
    project: Project;
}

const TaskList: React.FC<TaskListProps> = ({ project }) => {
    const { dispatch } = useProjectContext();
    const genAI = new GoogleGenerativeAI('AIzaSyBpazGd4i3ho6oGRdBTCIVlAdzChvHzIrY');
    const model = genAI.getGenerativeModel({ model: "gemini-pro" });
    const [aiResponse, setResponse] = useState<string | undefined>(project.aiResponse);
    const [loading, setLoading] = useState(false);
    const [userGoal, setUserGoal] = useState(project.goal);
    const [error, setError] = useState(false);

    async function aiRun() {
        if (aiResponse) {  // already set?
            console.log('AiRun skipped. Existing AI Response: ', aiResponse);
            return;
        }
        if (!userGoal || userGoal.length === 0) { // no goal
            console.log('AiRun skipped. No project goal');
            return;
        }
        setLoading(true);
        const promptTemplate = `I'm going to give you a goal. Your job is to create an ordered two-level list of tasks to achieve that goal in JSON format. 
        Generate a JSON object that outlines a project named ${project.name}. The JSON should include the given "Goal" describing the overall objective of the project, an array of "Tasks" 
        which are the main steps involved in the project, and an array of "Subtasks" where each element is an object linking a task to its specific detailed steps. Do not change the given goal.
        Use the following structure and do not include any additional JSON labels or quotes around the response:
{
  "Goal": "[the given brief description of the overall objective of the project]",
  "Tasks": ["[main step 1]", "[main step 2]", ...],
  "Subtasks": [
    {
      "Task": "[name of the task from the Tasks array]",
      "Subtasks": ["[detailed step 1]", "[detailed step 2]", ...]
    },
    // Additional tasks and their detailed steps as necessary
  ]
}

Ensure that each task and its subtasks are practical and logically ordered, considering the typical workflow of the specified project type. 
Provide enough detail in subtasks to allow someone unfamiliar with the project to understand the steps required.
The goal is: ${userGoal}`;
        try {
            const result = await model.generateContent(promptTemplate);
            const response = await result.response;
            const text = await response.text();
            setResponse(text);
            project.aiResponse = text;
            // dispatch({ type: 'SET_AI_RESPONSE', payload: { id: project.id, aiResponse: text } });
        } catch (error) {
            console.log(error);
            setError(true);
        }
        setLoading(false);
    }

    useEffect(() => {
        aiRun();
    }, []); // Empty dependency array means this runs once when the component mounts

    return (
        <View>
            {loading ? (
                <Animatable.View animation="jello" duration={5000} iterationCount="infinite" direction="normal">
                    <Text style={styles.description}>Making a task list for your goal [{userGoal}]</Text>
                </Animatable.View>
            ) : (
                userGoal === '' ? (
                    <Text style={styles.description}>Please enter a goal.</Text>
                ) : error ? (
                    <Text style={styles.description}>Something went wrong. Please try again.</Text>
                ) : aiResponse ? (
                    <CheckboxJsonView jsonData={aiResponse} /> // Provide a default empty string if aiResponse is undefined
                ) :
                    <Text style={styles.description}>Please enter a goal.</Text>
            )}
        </View>
    );
}

export default TaskList;
