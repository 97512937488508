import React from 'react';
import { Text } from 'react-native';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import StyledFirebaseAuth from '../utils/StyledFirebaseAuth';
import { styles } from '../styles';
import { Card } from '@rneui/themed';
import VideoBackground from '../components/VideoBackground';
import { auth as firebaseuiAuth } from 'firebaseui';

export default function SignUpScreen() {
    const uiConfig = {
        signInFlow: 'popup',
        signInSuccessUrl: '/', // Your post-login URL
        signInOptions: [
            {
                provider: GoogleAuthProvider.PROVIDER_ID,
                fullLabel: 'Sign up with Google',
                requireDisplayName: true,
                loginHintKey: 'login_hint',
            },
        ],
        tosUrl: 'https://appapt.com/dev',
        privacyPolicyUrl: 'https://appapt.com/privacy',
        // Required to enable one-tap sign-up credential helper.
        credentialHelper: firebaseuiAuth.CredentialHelper.GOOGLE_YOLO
    };
    return (
        <VideoBackground videoSource={require('../assets/aimsherpa.mp4')} >
            <Card containerStyle={styles.cardContainer} >
                <Text style={styles.description}>Create your free Aim Sherpa account:</Text>
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={getAuth()} />
            </Card>
        </VideoBackground>
    );
}
