import React from 'react';
import { View, Text } from 'react-native';
import { styles } from '../styles';
import MiniUserBadge from '../components/MiniUserBadge';
import { NativeStackHeaderProps } from '@react-navigation/native-stack';

export const CustomHeader: React.FC<NativeStackHeaderProps> = ({ navigation, route, options, back }) => {
    return (
        <View style={styles.navHeaderContainer}>
            <Text style={styles.navHeaderText}>Aim Sherpa \ {route.name}</Text>
            <MiniUserBadge />
        </View>
    );
};
