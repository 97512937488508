import { StyleSheet, ViewStyle, TextStyle, ImageStyle } from 'react-native';
import { CSSProperties } from 'react';

// Function to create border styles
const setBorder = (borderWidth: number, borderColor: string): ViewStyle => ({
    borderColor,
    borderWidth,
});

// Common style objects
const commonButtonStyles: ViewStyle = {
    alignSelf: 'center',
    backgroundColor: '#007AFF',
    borderRadius: 5,
    elevation: 5,
    shadowColor: '#000',
    shadowOffset: { height: 2, width: 0 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    width: '100%',
    ...setBorder(0, 'yellow'),
};

const commonContainerStyles: ViewStyle = {
    alignSelf: 'center',
    width: '100%',
    ...setBorder(0, 'red'),
};

const commonCardContainerStyles: ViewStyle = {
    ...commonContainerStyles,
    backgroundColor: 'white',
    elevation: 20,
    maxWidth: 796,
    opacity: 0.75,
}

const commonTextStyles: TextStyle = {
    color: '#4A4A4A',
    textAlign: 'center',
};

const commonContentStyles: ViewStyle = {
    width: '100%',
    flex: 1,
    padding: 5,
    ...setBorder(0, 'green'),
};

export const styles = StyleSheet.create({
    button: {
        ...commonButtonStyles,
        padding: 15,
        marginRight: 15,
    },
    buttonContainer: {
        ...commonContainerStyles,
        flexDirection: 'row',
        justifyContent: 'center',
    },
    buttonText: {
        ...commonTextStyles,
        color: '#FFFFFF',
        fontSize: 30,
        fontWeight: 'bold',
    } as TextStyle,
    cardContainer: {
        ...commonCardContainerStyles,
    },
    cardContainerFull: {
        ...commonCardContainerStyles,
        flex: 1,
        margin: 10,
    },
    center: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        backgroundColor: 'lightblue',
        flex: 1,
        padding: 5,
        verticalAlign: 'middle',
    },
    content: {
        ...commonContentStyles,
    },
    contentCenter: {
        ...commonContentStyles,
        justifyContent: 'center',
    },
    description: {
        alignSelf: 'center',
        color: '#4A4A4A',
        fontSize: 20,
        marginBottom: 20,
        textAlign: 'left',
        width: '100%',
    } as TextStyle,
    divider: {
        alignSelf: 'center',
        backgroundColor: '#1a1c2c',
        height: 5,
        marginTop: 20,
        marginVertical: 10,
        width: '60%',
    },
    footer: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 2,
    },
    footerText: {
        color: 'black',
        fontSize: 16,
        textAlign: 'center',
        textDecorationLine: 'underline',
    } as TextStyle,
    gradient: {
        flex: 1,
    },
    headerText: {
        color: '#002244',
        fontSize: 24,
        fontWeight: 'bold',
    } as TextStyle,
    image: {
        flex: 1,
        height: '100%',
        maxHeight: 600,
        maxWidth: 600,
        minHeight: 33,
        minWidth: 99,
        width: '100%',
    } as ImageStyle,
    imageBackground: {
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
        ...setBorder(0, 'purple'),
    },
    input: {
        ...setBorder(1, 'black'),
        borderStyle: 'dashed',
        flex: 1,
        marginRight: 5,
        padding: 10,
    },
    miniButton: {
        ...commonButtonStyles,
        padding: 5,
    },
    miniButtonText: {
        fontSize: 12,
    } as TextStyle,
    miniCardContainer: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    miniUserImage: {
        alignSelf: 'center',
        height: 48,
        marginLeft: 10,
        marginRight: 10,
        width: 48,
    },
    navHeaderContainer: {
        alignItems: 'center',
        flexDirection: 'row',
        padding: 10,
        ...setBorder(0, 'red'),
    },
    navHeaderText: {
        flex: 1,
        fontSize: 20,
    } as TextStyle,
    tableHeader: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: 'black',
        marginBottom: 10,
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: 'gray',
        paddingVertical: 10,
    },
    tableCell: {
        flex: 1,
        paddingHorizontal: 5,
        textAlign: 'center',
    },
    tableHeaderCell: {
        fontWeight: 'bold',
    },
    title: {
        alignSelf: 'center',
        color: '#002244',
        fontSize: 30,
        fontWeight: 'bold',
        marginBottom: 20,
        textAlign: 'center',
        textShadowColor: 'rgba(0, 0, 0, 0.75)',
        textShadowOffset: { width: -1, height: 1 },
        textShadowRadius: 4,
        width: '90%',
    } as TextStyle,
    projectContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginVertical: 8,
        padding: 16,
        //backgroundColor: '#f9f9f9',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#ddd',
    },
    projectName: {
        fontSize: 18,
    },
    inputRow: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 16,
    },
    inputContainerStyle: { // Style for container around input label and field
        flexDirection: 'row',
        alignItems: 'center',
        padding: 10,
        ...setBorder(0, 'red'),
    },
    innerContainer: { // Style for input field
        //borderBottomWidth: 0, // Remove bottom border
        width: '100%',
        flex: 4,
        ...setBorder(0, 'green'),
    },
    labelStyle: {  // Style for input label
        flex: 1,
        textAlign: 'right',
        marginRight: 10,
        ...setBorder(0, 'purple'),
    },
    inputStyle: {
        ...setBorder(2, 'purple'),
        borderBottomWidth: 1,
        borderColor: '#ccc',
        padding: 5,
    },
});
