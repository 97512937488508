import React from 'react';
import { useAuthentication } from '../utils/hooks/useAuthentication';
import UserStack from './userStack';
import AuthStack from './authStack';
import ProjectStack from './projectStack';
import { NavigationContainer } from '@react-navigation/native';

export default function RootNavigation() {
    const { user: authenticatedUser, alphaTester: authenticatedRole } = useAuthentication();
    return (
        <NavigationContainer>
            {!authenticatedUser ? <AuthStack /> : authenticatedRole ? <ProjectStack /> : <UserStack />}
        </NavigationContainer>
    )
}