import React, { useState, useEffect } from 'react';
import { ScrollView, Text, View } from 'react-native';
import { Button } from 'react-native-elements';
import { useNavigation } from '@react-navigation/native';
import { Project, useProjectContext } from '../utils/ProjectContext';
import { styles } from '../styles';
import { Input } from 'react-native-elements';
import TaskList from '../components/TaskList';

interface ProjectEditorProps {
    project: Project;
    onSave: (project: Project) => void;
}

const ProjectEditor: React.FC<ProjectEditorProps> = ({ project, onSave }) => {
    const [name, setName] = useState(project.name);
    const [goal, setGoal] = useState(project.goal);
    const [prj, setPrj] = useState(project);
    const [height, setHeight] = useState(0); // Initial height of text area for goal input
    const [created, setCreated] = useState(project.aiResponse ? 1 : 0);
    const [isApplyEnabled, setIsApplyEnabled] = useState(false);
    const navigation = useNavigation();
    const { state } = useProjectContext();

    useEffect(() => {
        console.log("Updated prj: ", prj, " key: ", created);
    }, [prj, created]);

    useEffect(() => {
        setIsApplyEnabled(name.trim() !== '' && goal.trim() !== '');
    }, [name, goal]);

    const onCancel = () => {
        navigation.goBack();
    }

    const onApply = (name: string, goal: string) => {
        if (prj.id === 'new')
            setPrj({ ...prj, id: Date.now().toString(), name, goal });
        else
            setPrj({ ...prj, name, goal });
        setCreated(prevKey => prevKey + 1); // Update the key to re-render TaskList
    }

    return (
        <ScrollView style={styles.content}>
            {state.projects.length === 0 ?
                <Text style={styles.headerText}>Let's create your first project...</Text> :
                project.id === 'new' ?
                    <Text style={styles.headerText}>Add Project</Text> :
                    <Text style={styles.headerText}>Edit Project</Text>}
            <View style={{ padding: 20, borderWidth: 0, borderColor: 'purple', width: '100%', alignSelf: 'stretch' }} >
                <Text>ID: {prj.id}</Text>
                <Input
                    containerStyle={styles.inputContainerStyle}
                    inputContainerStyle={styles.innerContainer}
                    inputStyle={styles.inputStyle}
                    labelStyle={styles.labelStyle}
                    value={name}
                    onChangeText={setName}
                    label="Project Name: *"
                    placeholder="<A short name for the project>"
                />
                <Input
                    containerStyle={styles.inputContainerStyle}
                    inputContainerStyle={[styles.innerContainer, { height: height }]}
                    inputStyle={[styles.inputStyle, { height: height }]}
                    labelStyle={styles.labelStyle}
                    multiline
                    value={goal}
                    onChangeText={setGoal}
                    onContentSizeChange={(e) => setHeight(e.nativeEvent.contentSize.height)}
                    label="Project Aim: *"
                    placeholder="<What is the goal of the project? Enter a precise description of the goal here. 
                                  This will be used to generate a list of tasks.>"
                />
                <Button buttonStyle={styles.button}
                    title={created ? "Update" : "Create"}
                    onPress={() => onApply(name, goal)}
                    disabled={!isApplyEnabled} />
            </View>
            {(prj.id === 'new' || prj.goal === '') ? null : <TaskList key={created} project={prj} />}
            <View style={styles.buttonContainer}>
                {navigation.canGoBack() ? <Button buttonStyle={styles.button} title="Cancel" onPress={onCancel} /> : null}
                {created ? <Button buttonStyle={styles.button} title="Save and Exit" onPress={() => onSave(prj)} /> : null}
            </View>
        </ScrollView>
    );
};

export default ProjectEditor;
