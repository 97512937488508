import React from 'react';
import { View } from 'react-native';
import { styles } from '../styles';
import WebLinearGradient from 'react-native-web-linear-gradient';
import TouchFooter from './TouchFooter';

interface VideoBackgroundProps {
    videoSource: string;
    children?: React.ReactNode;
}

const VideoBackground: React.FC<VideoBackgroundProps> = ({ videoSource, children }) => {
    //const [darkMode, setDarkMode] = useState(false);
    //const Gradient = Platform.OS === 'web' ? WebLinearGradient : LinearGradient;
    const Gradient = WebLinearGradient;
    const videoBackgroundStyle: React.CSSProperties = {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -100,
        overflow: 'hidden', // Ensure the video doesn't cause scrollbars
        borderWidth: 3,
        borderColor: 'red',
    };

    const videoStyle: React.CSSProperties = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: '100%',
        minHeight: '100%',
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain', // This will ensure the video is fully visible
        borderWidth: 0,
        borderColor: 'orange',
        pointerEvents: 'none', // Disable mouse interactions
        userSelect: 'none', // Prevent user selection on web
    };

    return (
        <View style={styles.content}>
            <Gradient colors={['#b8d8eb', '#e9f1f7']} style={styles.gradient}>
                <div style={videoBackgroundStyle}>
                    <video autoPlay loop muted style={videoStyle} playsInline>
                        <source src={videoSource} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                {children}
            </Gradient >
            <TouchFooter />
        </View >
    );
};

export default VideoBackground;
