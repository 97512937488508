import React from 'react';
import { View, Text } from 'react-native';
import { useAuthentication } from '../utils/hooks/useAuthentication';
import { Button } from 'react-native-elements';
import 'firebase/auth';
import { getAuth, signOut } from 'firebase/auth';
import { styles } from '../styles';
import { Card } from '@rneui/themed';
import VideoBackground from '../components/VideoBackground';
import ProjectList from '../components/ProjectList';
import { StackScreenProps } from '@react-navigation/stack';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { ProjectStackParamList } from '../navigation/projectStack';

export default function ProjectsScreen() {
  
    return (
        <VideoBackground videoSource={require('../assets/aimsherpa.mp4')}>
            <ProjectList />
        </VideoBackground>
    );
}
